
import { FacebookCredentials, FacebookLoginResponse } from '../../../types/ads';

declare global {
  interface Window {
    fbAsyncInit: object;
    FB: {
      api: Function;
      login: Function;
      logout: Function;
      init: Function;
      getLoginStatus: Function;
      AppEvents: {
        logPageView: Function;
      };
    };
  }
}

import Vue from 'vue';
import utils from '../../../util';
import { useLink } from '@c360/ui';
export default Vue.extend({
  props: ['accountId', 'bus'],
  data: (): {
    fbError: string;
    facebookAuth: FacebookLoginResponse;
    credentials: FacebookCredentials;
    showNote: boolean;
  } => ({
    fbError: null,
    credentials: null,
    facebookAuth: null,
    showNote: false,
  }),
  created() {
    window.fbAsyncInit = (): void => {
      window.FB.init({
        appId: '504308743599681',
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v13.0',
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      let js = null;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    this.bus.$on('logout', (id: string) => {
      this.logout(id);
    });
  },
  computed: {
    hasSufficientPermissions(): boolean {
      if (!this.facebookAuth || this.facebookAuth.status !== 'connected' || !this.credentials) return false;
      if (
        this.facebookAuth.authResponse.grantedScopes &&
        this.credentials.RequiredPermissions.every(scope =>
          this.facebookAuth.authResponse.grantedScopes.split(',').includes(scope),
        )
      ) {
        return true;
      } else return false;
    },
    showUserName(): boolean {
      return (
        this.credentials?.Connected &&
        !this.credentials?.RerequestPermission &&
        !this.$store.state.agencyAdmin.facebookLoading
      );
    },
    showLogin(): boolean {
      return (
        !this.hasSufficientPermissions &&
        !this.$store.state.agencyAdmin.credentials.facebookLoading &&
        this.hasIndividualRight(['AGENCY_ADMIN'])
      );
    },
  },
  methods: {
    hasIndividualRight(roles: Array<string>): boolean {
      return utils.hasRight(this.$store.state.customer.user, roles);
    },
    forceFacebookSync(): void {
      this.showNote = true;
      this.$emit('force-facebook-sync');
    },
    async saveFacebookAuto(fbResponse: FacebookLoginResponse): Promise<void> {
      const options = {
        userId: fbResponse.authResponse.userID,
        accessToken: fbResponse.authResponse.accessToken,
        agencyId: this.accountId,
      };

      try {
        var response = await this.$store.dispatch('agencyAdmin/addFacebookCredential', options);
        if (response) {
          this.credentials = response;
          this.$emit('connections');
        }
      } catch (exp) {
        this.fbError = exp.message;
      }
    },
    redirectTologin(): void {
      // send the correct base domain
      // our google app works with 3 links:
      // prod: https://compulse360.analytics.adportal.io
      // dev: https://compulse360.analytics.dev.adportal.io
      // stg: https://compulse360.analytics.stg.adportal.io

      const origin = window.location.origin;
      let sub = '';
      if (origin.includes('dev.')) sub = 'dev.';
      else if (origin.includes('staging.')) sub = 'staging.';
      let sendURL = useLink({
        product: 'analytics',
        agency: 'compulse360',
        env: utils.getEnvForProductLink(),
      });

      window.open(`${sendURL}/facebook-grant-access?aid=${this.accountId}`, '_blank');
    },
    logout(id: string): void {
      if (id === this.credentials?.UserId) {
        window.FB.logout(() => {
          this.credentials = null;
          this.fbError = null;
          this.facebookAuth = null;
        });
      }
    },
  },
});
